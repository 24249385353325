import { createSlice } from '@reduxjs/toolkit'
import {
  fetchGamblingTable,
  fetchGamblingTableList,
  deleteGamblingTable,
  addGamblingTable,
  AddPackageToGamblingTable,
  changeGamblingTable,
  takeGamblingTableBalance,
  putGamblingTableBalance,
  fetchGamblingTableSessions,
  fetchSessions,
  closeSession,
  takeTablePollscore,
  putTableHighscore,
  takeTableHighscore,
  fetchSessionSpin,
  changeTableBank,
  tableCmdReload,
  tableCmdHome,
  setMathType,
  enableApi,
} from '../services/GamblingTable'
import { changeArr } from '../utils/changeArr'
import {
  treeInfoIsLoading,
  treeInfoSuccess,
  treeInfoFailure,
  treeInfoChangeBalance,
} from './treeInfoReducer'
import { paymentFormIsLoading, paymentFormSucces, paymentFormIsError } from './paymentReducer'
import {
  ITable,
  ISpin,
  ISession,
  isConfirmType,
  TableData,
  IPaymentData,
  ErrorType,
} from '../interfaces'
import { AppThunk } from './store'

interface TableInitialState {
  data: ITable | null
  loading: boolean
  error: ErrorType[]
  tables: {
    count: {
      total: number
      online: number
      game: number
    }
    data: ITable[]
    loading: boolean
    error: ErrorType[]
  }
  sessions: {
    countPage: number
    data: ISession[]
    loading: boolean
    error: ErrorType[]
  }
  spins: {
    countPage: number
    data: ISpin[]
    loading: boolean
    error: ErrorType[]
  }
  spin: {
    data: null | ISpin
    loading: boolean
    error: ErrorType[]
  }
  math: {
    pageCount: number
    data: any
    loading: boolean
    error: ErrorType[]
  }
  mathReset: {
    loading: boolean
    error: ErrorType[]
  }
  form: {
    loading: boolean
    error: ErrorType[]
  }
}

export const initialState: TableInitialState = {
  data: null,
  loading: false,
  error: [],
  tables: {
    count: {
      total: 0,
      online: 0,
      game: 0
    },
    data: [],
    loading: false,
    error: [],
  },
  sessions: {
    countPage: 1,
    data: [],
    loading: false,
    error: [],
  },
  spins: {
    countPage: 1,
    data: [],
    loading: false,
    error: [],
  },
  spin: {
    data: null,
    loading: false,
    error: [],
  },
  math: {
    pageCount: 0,
    data: null,
    loading: false,
    error: [],
  },
  mathReset: {
    loading: false,
    error: [],
  },
  form: {
    loading: false,
    error: [],
  },
}
export const slice = createSlice({
  name: 'table',
  initialState,
  reducers: {
    tableIsLoading: (state) => {
      state.loading = true
    },
    tableSuccess: (state, { payload }) => {
      state.loading = false
      state.error = []
      state.data = payload
    },
    tableFailure: (state, { payload }) => {
      state.loading = false
      state.error = payload
    },
    tableDataChangeBalance: (state, { payload }) => {
      if (state.data) {
        state.data = {
          ...state.data,
          ...payload,
        }
      }
    },
    tablesListIsLoading: (state) => {
      state.tables.loading = true
    },
    tablesListSuccess: (state, { payload }) => {
      state.tables.loading = false
      state.tables.error = []
      state.tables.data = payload
      state.tables.count.total = state.tables.data.length;
      state.tables.count.online = state.tables.data.reduce((prev, cur) => prev += cur.online, 0);
      state.tables.count.game = state.tables.data.reduce((prev, cur) => prev += +(cur.currentGame !== null), 0);
    },
    updateTablesListSuccess: (state, { payload }) => {
      state.tables.loading = false
      state.tables.error = []
      const tmp: number[] = [];
      payload.forEach((t: ITable, i: number) => (tmp[t.id] = i));
      state.tables.data = state.tables.data.map(t => t.id in tmp ? payload[tmp[t.id]] : t);
      state.tables.count.total = state.tables.data.length;
      state.tables.count.online = state.tables.data.reduce((prev, cur) => prev += cur.online, 0);
      state.tables.count.game = state.tables.data.reduce((prev, cur) => prev += +(cur.currentGame !== null), 0);
    },
    tablesHallListSuccess: (state, { payload }) => {
      state.tables.loading = false
      state.tables.error = []
      let del = false;
      let used: number[] = [];
      const tmp: any = {};
      payload.data.forEach((e: any) => {
        tmp[e.id] = e;
      });
      let data: any = state.tables.data.map(t => {
        if (+t.hall_id === +payload.hall_id) {
          if (t.id in tmp) {
            used.push(t.id);
            return tmp[t.id];
          }
          del = true;
          return null;
        }
        return t;
      });
      if (used.length < payload.data.length) {
        data = payload.data.filter((e: any) => !used.includes(e.id)).concat(data);
      }
      state.tables.data = del ? data.filter((e: any) => e) : data;
    },
    updateTablesHallListSuccess: (state, { payload }) => {
      state.tables.loading = false
      state.tables.error = []
      const tmp: number[] = [];
      payload.data.forEach((e: ITable, i: number) => (tmp[e.id] = i));
      state.tables.data = state.tables.data.map(t => (t.id in tmp ? payload.data[tmp[t.id]] : t));
    },
    tablesSessionsFailure: (state, { payload }) => {
      state.sessions.loading = false
      state.sessions.error = payload
    },
    tablesSessionsIsLoading: (state) => {
      state.sessions.loading = true
    },
    tablesSessionsSuccess: (state, { payload }) => {
      state.sessions.loading = false
      state.sessions.error = []
      state.sessions.data = payload
    },
    setTablesSessionsPageCount: (state, { payload }) => {
      state.sessions.countPage = payload
    },
    tablesSpinsFailure: (state, { payload }) => {
      state.spins.loading = false
      state.spins.error = payload
    },
    tablesSpinsIsLoading: (state) => {
      state.spins.loading = true
    },
    tablesSpinsSuccess: (state, { payload }) => {
      state.spins.loading = false
      state.spins.error = []
      state.spins.data = payload
    },
    setTablesSpinsPageCount: (state, { payload }) => {
      state.spins.countPage = payload
    },
    tableSpinFailure: (state, { payload }) => {
      state.spins.loading = false
      state.spins.error = payload
    },
    tableSpinIsLoading: (state) => {
      state.spin.loading = true
    },
    tableSpinSuccess: (state, { payload }) => {
      state.spin.loading = false
      state.spin.error = []
      state.spin.data = payload
    },
    tablesListFailure: (state, { payload }) => {
      state.tables.loading = false
      state.tables.error = payload
    },
    tablesListChange: (state, { payload }) => {
      state.tables.data = [...changeArr(state.tables.data, payload.id, payload)]
    },
    tablesListDeleteItem: (state, { payload }) => {
      state.tables.data = [...state.tables.data.filter((i) => i.id !== payload)]
    },
    tablesListItemAdd: (state, { payload }) => {
      state.tables.data = [...state.tables.data, payload]
    },
    changeTableCurrentGame: (state, { payload }) => {
      if (state.data) {
        state.data.currentGame = payload
      }
    },
    tableChangeBalance: (state, { payload }) => {
      const array = state.tables.data
      const id = array.findIndex((i) => i.id === payload.id)
      if (id !== -1) {
        state.tables.data = [
          ...array.slice(0, id),
          {
            ...array[id],
            balance: payload.balance,
          },
          ...array.slice(id + 1, array.length),
        ]
      }
    },
    tableChangeMathType: (state, { payload }) => {
      const array = state.tables.data
      const id = array.findIndex((i) => i.id === payload.id)
      if (id !== -1) {
        state.tables.data = [
          ...array.slice(0, id),
          {
            ...array[id],
            math_type: payload.math_type,
          },
          ...array.slice(id + 1, array.length),
        ]
      }
    },
    tableChangeIsOut: (state, { payload }) => {
      const array = state.tables.data
      const id = array.findIndex((i) => i.id === payload.id)
      if (id !== -1) {
        state.tables.data = [
          ...array.slice(0, id),
          {
            ...array[id],
            is_out: payload.is_out,
          },
          ...array.slice(id + 1, array.length),
        ]
      }
    },
    tablesListChangeHighscore: (state, { payload }) => {
      const array = state.tables.data
      const id = array.findIndex((i) => i.id === Number(payload.id))
      if (id !== -1) {
        state.tables.data = [
          ...array.slice(0, id),
          {
            ...array[id],
            highscore: payload.amount,
          },
          ...array.slice(id + 1, array.length),
        ]
      }
    },
    tablesListChangePollscore: (state, { payload }) => {
      const array = state.tables.data
      const id = array.findIndex((i) => i.id === Number(payload.id))
      if (id !== -1) {
        state.tables.data = [
          ...array.slice(0, id),
          {
            ...array[id],
            pollscore: payload.amount,
          },
          ...array.slice(id + 1, array.length),
        ]
      }
    },
    tablesListChangeCurrentGame: (state, { payload }) => {
      const array = state.tables.data;
      const id = array.findIndex((i) => i.id === Number(payload.id));
      if (id !== -1 && ((state.tables.data[id].currentGame && !payload.currentGame) || (!state.tables.data[id].currentGame && payload.currentGame))) {
        state.tables.data = [
          ...array.slice(0, id),
          {
            ...array[id],
            currentGame: payload.currentGame,
          },
          ...array.slice(id + 1, array.length),
        ];
        state.tables.count.game += payload.currentGame ? 1 : -1;
      }
    },
    tablesListChangeOnline: (state, { payload }) => {
      const array = state.tables.data;
      const id = array.findIndex((i) => i.id === Number(payload.id));
      if (id !== -1 && (state.tables.data[id].online !== payload.online)) {
        state.tables.data = [
          ...array.slice(0, id),
          {
            ...array[id],
            online: payload.online,
          },
          ...array.slice(id + 1, array.length),
        ];
        state.tables.count.online += payload.online ? 1 : -1;
      }
    },
    tableBindChange: (state, { payload }) => {
      const array = state.tables.data
      const id = array.findIndex((i) => i.id === Number(payload.id))
      if (id !== -1) {
        state.tables.data = [
          ...array.slice(0, id),
          {
            ...array[id],
            playerConnection: payload.player_id,
          },
          ...array.slice(id + 1, array.length),
        ]
      }
    },
    formIsLoading: (state) => {
      state.form.loading = true
    },
    formSucces: (state) => {
      state.form.loading = false
      state.form.error = []
    },
    formIsError: (state, { payload }) => {
      state.form.loading = false
      state.form.error = payload
    },
    changeBank: (state, { payload }) => {
      if (state.data) {
        state.data.bank = payload
      }
    },
  },
})

export const getTable = (id: number): AppThunk => {
  return async (dispatch) => {
    dispatch(tableIsLoading())

    return fetchGamblingTable(id)
      .then((res) => {
        if (res.data.code === 200) {
          dispatch(tableSuccess(res.data.data))
        } else {
          if (typeof res.data.data.length !== 'number') {
            dispatch(tableFailure([res.data.data]))
          } else {
            dispatch(tableFailure(res.data.data))
          }
        }
      })
      .catch((err) => dispatch(tableFailure(err)))
  }
}

let tables: ITable[] = []
export const getAlltables = (page: number, limit: number): AppThunk => {
  return async (dispatch) => {
    dispatch(tablesListIsLoading())

    return fetchGamblingTableList(page, limit)
      .then((res) => {
        if (res.data.code === 200) {
          tables = [...tables, ...res.data.data]
          if (res.headers['x-pagination-current-page'] < res.headers['x-pagination-page-count']) {
            dispatch(getAlltables(Number(res.headers['x-pagination-current-page']) + 1, limit))
            dispatch(tablesListSuccess(tables))
          } else {
            dispatch(tablesListSuccess(tables))
            tables = []
          }
        } else {
          if (typeof res.data.data.length !== 'number') {
            dispatch(tablesListFailure([res.data.data]))
          } else {
            dispatch(tablesListFailure(res.data.data))
          }
        }
      })
      .catch((err) => dispatch(tablesListFailure(err)))
  }
}

export const getTablesList = (search?: any, sort?: string): AppThunk => {
  return async (dispatch) => {
    dispatch(tablesListIsLoading())

    return fetchGamblingTableList(1, 50, search, sort)
      .then((res) => {
        if (res.data.code === 200) {
          dispatch(search && search.hall_id ? updateTablesHallListSuccess({ data: res.data.data, hall_id: search.hall_id }) : updateTablesListSuccess(res.data.data))
        } else {
          if (typeof res.data.data.length !== 'number') {
            dispatch(tablesListFailure([res.data.data]))
          } else {
            dispatch(tablesListFailure(res.data.data))
          }
        }
      })
      .catch((err) => dispatch(tablesListFailure(err)))
  }
}

export const deleteTableListItem = (
  id: number | undefined,
  setIsConfitrm: (isConfirm: isConfirmType) => void
): AppThunk => {
  return async (dispatch) => {
    dispatch(formIsLoading())
    return (
      id &&
      deleteGamblingTable(id)
        .then((res) => {
          if (res.data.code === 200) {
            dispatch(tablesListDeleteItem(id))
            dispatch(formSucces())
            setIsConfitrm({ open: false })
          } else {
            if (typeof res.data.data.length !== 'number') {
              dispatch(formIsError([res.data.data]))
            } else {
              dispatch(formIsError(res.data.data))
            }
          }
        })
        .catch((err) => dispatch(formIsError(err)))
    )
  }
}

export const handlerAddTable = (
  data: TableData,
  packages: any,
  hideForm: (table_id: number) => void
): AppThunk => {
  return async (dispatch) => {
    dispatch(formIsLoading())
    return addGamblingTable(data)
      .then((res) => {
        if (res.data.code === 200) {
          dispatch(tablesListItemAdd(res.data.data))
          AddPackageToGamblingTable(packages, res.data.data.id)
          hideForm(res.data.data.id)
          dispatch(formSucces())
        } else {
          if (typeof res.data.data.length !== 'number') {
            dispatch(formIsError([res.data.data]))
          } else {
            dispatch(formIsError(res.data.data))
          }
        }
      })
      .catch((err) => dispatch(formIsError(err)))
  }
}

export const handlerChangeTable = (
  data: TableData,
  packages: any,
  hideForm: () => void,
  tableId: number,
  info: boolean | undefined
): AppThunk => {
  if ("remote_url" in data && data.remote_url) {
    data.apiForm = { remote_url: data.remote_url! };
    delete data.remote_url;
  }
  return async (dispatch) => {
    dispatch(formIsLoading());
    const f = () => changeGamblingTable(data, tableId)
      .then((res) => {
        if (res.data.code === 200) {
          dispatch(tablesListChange(res.data.data))
          dispatch(tableSuccess(res.data.data))
          dispatch(formSucces())
          info && dispatch(treeInfoSuccess(res.data.data))
          hideForm()
        } else {
          if (typeof res.data.data.length !== 'number') {
            dispatch(formIsError([res.data.data]))
          } else {
            dispatch(formIsError(res.data.data))
          }
          info && dispatch(treeInfoFailure(res.data.data))
        }
      })
      .catch((err) => {
        dispatch(formIsError(err))
        info && dispatch(treeInfoFailure(err))
      });
    return packages === null ? f() : AddPackageToGamblingTable(packages, tableId).then((res) => {
      if (res.data.code === 200) {
        info && dispatch(treeInfoIsLoading());
        f();
      }
    })
  }
}

export const handleChangeTableStatus = (
  table: any,
  setIsBlock: (isBlock: isConfirmType) => void,
  info?: boolean | undefined
): AppThunk => {
  const data: any = {
    name: table.name,
    hall_id: table.hall_id,
    login: '',
    password: '',
    status: !!table?.status ? 0 : 10,
  }
  return async (dispatch) => {
    dispatch(formIsLoading())
    info && dispatch(treeInfoIsLoading())
    return (
      table.id &&
      changeGamblingTable(data, table.id)
        .then((res) => {
          if (res.data.code === 200) {
            dispatch(tablesListChange(res.data.data))
            dispatch(formSucces())
            info && dispatch(treeInfoSuccess(res.data.data))
            setIsBlock({ open: false })
          } else {
            if (typeof res.data.data.length !== 'number') {
              dispatch(formIsError([res.data.data]))
            } else {
              dispatch(formIsError(res.data.data))
            }
            info && dispatch(treeInfoFailure(res.data.data))
          }
        })
        .catch((err) => {
          dispatch(formIsError(err))
          info && dispatch(treeInfoFailure(err))
        })
    )
  }
}

export const handlerPutTableBalance = (
  data: IPaymentData,
  table: ITable,
  info?: boolean
): AppThunk => {
  return async (dispatch) => {
    dispatch(paymentFormIsLoading())
    return putGamblingTableBalance(data, table.id)
      .then((res) => {
        if (res.data.code === 200) {
          info && dispatch(treeInfoChangeBalance(res.data.data.balance))
          dispatch(
            tableChangeBalance({
              id: Number(table.id),
              balance: res.data.data.balance,
            })
          )
          // dispatch(
          //   changeHallBalanceValue({
          //     id: table.hall.id,
          //     balance: -data.amount,
          //   })
          // )
          dispatch(paymentFormSucces(res.data.data))
        } else {
          if (typeof res.data.data.length !== 'number') {
            dispatch(paymentFormIsError([res.data.data]))
          } else {
            dispatch(paymentFormIsError(res.data.data))
          }
        }
      })
      .catch((err) => dispatch(paymentFormIsError(err)))
  }
}

export const handlerSetMathType = (tableId: number, mathType: number): AppThunk => {
  return async (dispatch) => {
    return setMathType(tableId, mathType).then((res) => {
      if (res.data.code === 200) {
        dispatch(tableChangeMathType({ id: tableId, math_type: mathType }));
      }
    });
  }
}

export const handlerTakeTableBalance = (
  data: IPaymentData,
  table: ITable,
  info?: boolean
): AppThunk => {
  return async (dispatch) => {
    dispatch(paymentFormIsLoading())
    return takeGamblingTableBalance(data, table.id)
      .then((res) => {
        if (res.data.code === 200) {
          info && dispatch(treeInfoChangeBalance(res.data.data.balance))
          dispatch(
            tableChangeBalance({
              id: Number(table.id),
              balance: res.data.data.balance,
            })
          )
          // dispatch(
          //   changeHallBalanceValue({
          //     id: table.hall.id,
          //     balance: data.amount,
          //   })
          // )
          dispatch(paymentFormSucces(res.data.data))
        } else {
          if (typeof res.data.data.length !== 'number') {
            dispatch(paymentFormIsError([res.data.data]))
          } else {
            dispatch(paymentFormIsError(res.data.data))
          }
        }
      })
      .catch((err) => dispatch(paymentFormIsError(err)))
  }
}

export const fetchTableSessions = (id: number, page: number, limit: number): AppThunk => {
  return async (dispatch) => {
    dispatch(tablesSessionsIsLoading())
    return fetchGamblingTableSessions(id, page, limit)
      .then((res) => {
        if (res.data.code === 200) {
          dispatch(tablesSessionsSuccess(res.data.data))
          dispatch(setTablesSessionsPageCount(res.headers['x-pagination-page-count']))
        } else {
          if (typeof res.data.data.length !== 'number') {
            dispatch(tablesSessionsFailure([res.data.data]))
          } else {
            dispatch(tablesSessionsFailure(res.data.data))
          }
        }
      })
      .catch((err) => dispatch(tablesSessionsFailure(err)))
  }
}

export const fetchTableSpins = (id: number, page: number, limit: number): AppThunk => {
  return async (dispatch) => {
    dispatch(tablesSpinsIsLoading())
    return fetchSessions(id, page, limit)
      .then((res) => {
        if (res.data.code === 200) {
          dispatch(tablesSpinsSuccess(res.data.data))
          dispatch(setTablesSpinsPageCount(res.headers['x-pagination-page-count']))
        } else {
          if (typeof res.data.data.length !== 'number') {
            dispatch(tablesSpinsFailure([res.data.data]))
          } else {
            dispatch(tablesSpinsFailure(res.data.data))
          }
        }
      })
      .catch((err) => dispatch(tablesSpinsFailure(err)))
  }
}

export const fetchTableSpin = (id: number, page: number, limit: number): AppThunk => {
  return async (dispatch) => {
    dispatch(tableSpinIsLoading())
    return fetchSessionSpin(id, page, limit)
      .then((res) => {
        if (res.data.code === 200) {
          dispatch(tableSpinSuccess(res.data.data))
        } else {
          if (typeof res.data.data.length !== 'number') {
            dispatch(tableSpinFailure([res.data.data]))
          } else {
            dispatch(tableSpinFailure(res.data.data))
          }
        }
      })
      .catch((err) => dispatch(tablesSpinsFailure(err)))
  }
}

export const closeTableSession = (
  id: number,
  setIsConfitrm: (isConfirm: isConfirmType) => void
): AppThunk => {
  return async (dispatch) => {
    dispatch(formIsLoading())
    return closeSession(id)
      .then((res) => {
        if (res.data.code === 200) {
          dispatch(tablesListChangeCurrentGame({ id: id, currentGame: null }))
          dispatch(formSucces())
          setIsConfitrm({ open: false })
        } else {
          dispatch(formIsError(res.data.data))
        }
      })
      .catch((err) => dispatch(formIsError(err)))
  }
}

export const handleTakeTableHighscore = (id: number, hideForm: () => void): AppThunk => {
  return async (dispatch) => {
    dispatch(formIsLoading())
    return takeTableHighscore(id)
      .then((res) => {
        if (res.data.code === 200) {
          dispatch(
            tablesListChangeHighscore({
              id: id,
              amount: res.data.data.amount,
            })
          )
          dispatch(formSucces())
          hideForm()
        } else {
          dispatch(formIsError(res.data.data))
        }
      })
      .catch((err) => dispatch(formIsError(err)))
  }
}

export const handlePutTableHighscore = (
  id: number,
  amount: number,
  hideForm: () => void
): AppThunk => {
  return async (dispatch) => {
    dispatch(formIsLoading())
    return putTableHighscore(id, amount)
      .then((res) => {
        if (res.data.code === 200) {
          dispatch(
            tablesListChangeHighscore({
              id: id,
              amount: res.data.data.amount,
            })
          )
          dispatch(formSucces())
          hideForm()
        } else {
          dispatch(formIsError(res.data.data))
        }
      })
      .catch((err) => dispatch(formIsError(err)))
  }
}

export const handleTakeTablePollscore = (id: number, hideForm: () => void, currency_id: string, pollscore: number): AppThunk => {
  return async (dispatch) => {
    dispatch(formIsLoading())
    return takeTablePollscore(id, currency_id, pollscore)
      .then((res) => {
        if (res.data.code === 200) {
          dispatch(
            tablesListChangePollscore({
              id: id,
              amount: res.data.data.amount,
            })
          )
          dispatch(formSucces())
          hideForm()
        } else {
          dispatch(formIsError(res.data.data))
        }
      })
      .catch((err) => dispatch(formIsError(err)))
  }
}

export const handleChangeBank = (data: any, hall_id: number, hideForm: () => void): AppThunk => {
  return async (dispatch) => {
    dispatch(formIsLoading())
    return changeTableBank(data, hall_id)
      .then((res) => {
        if (res.data.code === 200) {
          dispatch(formSucces())
          dispatch(changeBank(res.data.data.table))
          hideForm()
        } else {
          dispatch(formIsError(res.data.data))
        }
      })
      .catch((err) => dispatch(formIsError(err)))
  }
}

export const handleTableReload = (table_id: number, hideForm: any): AppThunk => {
  return async (dispatch) => {
    dispatch(formIsLoading())
    return tableCmdReload(table_id)
      .then((res) => {
        if (res.data.code === 200) {
          dispatch(formSucces())
          hideForm({ open: false })
        } else {
          dispatch(formIsError(res.data.data))
        }
      })
      .catch((err) => dispatch(formIsError(err)))
  }
}

export const handleTableHome = (table_id: number, hideForm: any): AppThunk => {
  return async (dispatch) => {
    dispatch(formIsLoading())
    return tableCmdHome(table_id)
      .then((res) => {
        if (res.data.code === 200) {
          dispatch(formSucces())
          hideForm({ open: false })
        } else {
          dispatch(formIsError(res.data.data))
        }
      })
      .catch((err) => dispatch(formIsError(err)))
  }
}

export const fetchEnableApi = (table_id: number): AppThunk => {
  return async (dispatch) => {
    return enableApi(table_id)
      .then((res) => {
        if (res.data.code === 200) {
          dispatch(getTable(res.data.data.id));
        }
      });
  }
}

export const {
  tableIsLoading,
  tableSuccess,
  tableFailure,
  tableDataChangeBalance,
  tablesListIsLoading,
  tablesListSuccess,
  updateTablesListSuccess,
  tablesHallListSuccess,
  updateTablesHallListSuccess,
  tablesListFailure,
  tablesListChange,
  tablesListDeleteItem,
  tablesListItemAdd,
  tableChangeBalance,
  tableChangeMathType,
  tablesListChangeHighscore,
  tablesListChangePollscore,
  tablesListChangeCurrentGame,
  tablesListChangeOnline,
  tablesSessionsFailure,
  tablesSessionsIsLoading,
  tablesSessionsSuccess,
  setTablesSessionsPageCount,
  tablesSpinsFailure,
  tablesSpinsIsLoading,
  tablesSpinsSuccess,
  tableSpinFailure,
  tableSpinIsLoading,
  tableSpinSuccess,
  tableBindChange,
  setTablesSpinsPageCount,
  changeTableCurrentGame,
  formIsLoading,
  formSucces,
  formIsError,
  changeBank,
  tableChangeIsOut
} = slice.actions

interface IState {
  table: TableInitialState
}

export const tableSelector = (state: IState) => state.table

export default slice.reducer
